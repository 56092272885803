import React, { useEffect, useState } from 'react';
import './App.css';

const sectors = [
  'sector1',
  'sector2',
  'sector3',
  'sector4',
  'sector5',
  'sector6',
  'sector7',
  'sector8',
  'sector9',
];

function App() {
  const [sectorStatus, setSectorStatus] = useState({
    sector1: 'green',
    sector2: 'green',
    sector3: 'green',
    sector4: 'green',
    sector5: 'green',
    sector6: 'green',
    sector7: 'green',
    sector8: 'green',
    sector9: 'green',
  });

  useEffect(() => {
    const ws = new WebSocket('ws://34.36.72.72:80');

    ws.onmessage = (event) => {
      const updatedStatus = JSON.parse(event.data);
      setSectorStatus(updatedStatus);
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <div className="App">
      <h1>Building Monitor</h1>
      <div className="sectors">
        {sectors.map((sector) => (
          <div
            key={sector}
            className="sector"
            style={{ backgroundColor: sectorStatus[sector] }}
          >
            {sector}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;

